<template>
  <div style="font-weight: 600;">
    <div class="d-flex justify-content-between mb-4">
      <div class="d-flex" style="line-height: 1.5;">
        <div>
          <img id="rel_logo" v-if="company.logo_url" :src="company.logo_url" class="logo-w" style="margin-left: auto;width: 114px;" alt="">
        </div>
      </div>

      <div>
        <h2 style="color: #0C6F58;">{{title}}</h2>
        <p><span>{{ $t('report_main.from_date') }}: </span> <span>{{fromDate}}</span></p>
        <p><span>{{ $t('report_main.to_date') }}: </span> <span>{{toDate}}</span></p>
      </div>
    </div>
    <div class="d-flex mb-4">
      <div style="border-right: 1px solid #727272; padding: 0 15px;width:50%;">
        <p><span style="color: #0C6F58;">{{ $t('report_main.issued_from') }}: </span> <span> {{company.business_name }}</span></p>
        <p><span style="color: #0C6F58;">{{ $t('report_main.commercial_register') }}: </span> <span> {{company.business_record }}</span></p>
        <p><span style="color: #0C6F58;">{{ $t('report_main.tax_number') }}: </span> <span> {{company.tax_no }}</span></p>
        <p><span style="color: #0C6F58;">{{ $t('report_main.address') }}: </span> <span> {{ company.country_name }} - {{ company.city_name }} - {{ company.address }}</span></p>
      </div>
      <div style="border-right: 1px solid #727272; padding: 0 15px;width:50%;" v-if="detectionFor">
        <p><span style="color: #0C6F58">{{ $t('report_main.issued_to') }}: </span> <span> {{detectionFor.name ? detectionFor.name : (detectionFor.business_name ? detectionFor.business_name : '') }}</span></p>
        <p><span style="color: #0C6F58">{{ $t('report_main.commercial_register') }}: </span> <span> {{detectionFor.commercial_register ? detectionFor.commercial_register : (detectionFor.buisnessno ? detectionFor.buisnessno : '') }}</span></p>
        <p><span style="color: #0C6F58">{{ $t('report_main.tax_number') }}: </span> <span> {{detectionFor.tax_register ? detectionFor.tax_register : (detectionFor.taxno ? detectionFor.taxno : '') }}</span></p>
        <p><span style="color: #0C6F58">{{ $t('report_main.address') }}: </span> <span> {{ detectionFor.country_name }} - {{ detectionFor.city_name }} - {{ detectionFor.address }}</span></p>
      </div>
    </div>
  </div>


</template>

<script>
import imageS3Url from "@/core/config/mix/imageS3Url";

export default {
  name: "report-header-for-supported",
  props: {detectionFor: {}, company:{}, fromDate: null, toDate: null, title: null},
  data() {
    return {
      rel_logo: null,
      data: [],
    }
  },
  methods: {
    ...imageS3Url
  },

}
</script>

<style scoped>
.cairo-400 {font-family: "Cairo", sans-serif;font-optical-sizing: auto;font-weight: 400;font-style: normal;font-variation-settings: "slnt" 0;}
</style>