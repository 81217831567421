<template>
  <div>
    <!--begin::balance-->
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="form-group col-md-4 mb-2 mt-2">
                <label>{{ $t('simple_suppliers_balance_report.supplier') }}</label>
                <multiselect v-model="supplier"
                             :multiple="false"
                             :options="suppliers" :placeholder="$t('simple_suppliers_balance_report.supplier')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="business_name"
                             track-by="id"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-4 mb-2 mt-2">
                <label for="from_date">{{ $t('from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>

              <div class="form-group col-md-4 mb-2 mt-2">
                <label for="to_date">{{ $t('to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                  {{ $t('display_report') }}
                </button>
                <button id="resetFilter" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <div class="card card-custom mb-5">
      <div class="card-body">
        <div class="row mb-10">
          <div id="summaryTableTitle" class="col-12 mt-10">
            <h4 class="text-center">{{ $t('MENU.simple_suppliers_balance_report') }}</h4>
            <h5 class="text-center">
              <span v-if="date_time_now">{{ $t('time') }} {{ date_time_now }}</span>
            </h5>
            <h5 class="text-center">
              <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }} </span>
              <span v-if="filters.to_date"> {{ $t('to') }} {{ filters.to_date }}</span>
            </h5>
          </div>
          <div class="d-flex col-12 justify-content-end p-4">
            <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
              <download-excel
                  :fetch="getDataList"
                  :fields="json_fields"
                  :name="$t('MENU.simple_suppliers_balance_report')+'.xls'"
                  class="dropdown-item">
                <i class="la la-file-excel"></i>{{ $t('excel') }}
              </download-excel>
              <button class="dropdown-item" @click="printData('summaryTable')">
                <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
              </button>
              <button class="dropdown-item" @click="printPDFData('summaryTable')">
                <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
              </button>
            </b-dropdown>

          </div>
        </div>
        <div id="balancesTable" class="balancesTable col-12 mb-4 mt-10">
          <div
              style="display: flex; justify-content: space-around;text-align: center;background-color: #f8f9fa;border-radius: 5px;align-items: center;height: 85px;">
            <div>
              <h4>{{ $t('simple_suppliers_balance_report.opening_balance') }}</h4>
              <h4>{{ balances.opening_balance ? balances.opening_balance : '0' }}</h4>
            </div>
            <div>
              <h4>{{ $t('simple_suppliers_balance_report.total_bills') }}</h4>
              <h4>{{ balances.total_purchase ? balances.total_purchase : '0' }}</h4>
            </div>
            <div>
              <h4>{{ $t('simple_suppliers_balance_report.amount_paid') }}</h4>
              <h4>{{ balances.total_payments ? balances.total_payments : '0' }}</h4>
            </div>
            <div>
              <h4> {{ $t('simple_suppliers_balance_report.amount_due') }}</h4>
              <h4>{{ balances.debit ? balances.debit : '0' }}</h4>
            </div>
          </div>
        </div>
        <div class="col-12 mt-10">
          <div class="table-responsive">
            <table :id="'summaryTable'" :class="'summaryTable'" class="table">
              <thead>
              <tr style="background-color: #f8f9fa;text-align: center;">
                <th>{{ $t('simple_suppliers_balance_report.date') }}</th>
                <th>{{ $t('simple_suppliers_balance_report.type') }}</th>
                <th>{{ $t('simple_suppliers_balance_report.type_id') }}</th>
                <!--                <th>{{ $t('simple_suppliers_balance_report.reference') }}</th>-->
                <th>{{ $t('simple_suppliers_balance_report.debit_after') }}</th>
                <th>{{ $t('simple_suppliers_balance_report.credit_after') }}</th>
                <th>{{ $t('simple_suppliers_balance_report.amount') }}</th>
                <th>{{ $t('simple_suppliers_balance_report.currency') }}</th>
              </tr>
              </thead>
              <tbody style="text-align: center;">
              <tr v-for="(row, index) in data" :key="'c'+getRandom()+index">
                <td>{{ row.reference_date }}</td>
                <td>{{ row.type }}</td>
                <td>{{ row.model_code }}</td>
                <!--                <td>{{ row.reference_date }}</td>-->
                <td>{{ row.debit_after }}</td>
                <td>{{ row.credit_after }}</td>
                <td>{{ row.amount }}</td>
                <td>{{ row.currency_name }}</td>
              </tr>
              </tbody>
              <tfoot style="text-align: center;">
              <tr>
                <td>{{ toDate }}</td>
                <td colspan="4">{{ $t('simple_suppliers_balance_report.net_movement') }}</td>
                <td>{{ totals.total }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ toDate }}</td>
                <td colspan="4">{{ $t('simple_suppliers_balance_report.closing_balance') }}</td>
                <td>{{ totals.final_balance }}</td>
                <td></td>
              </tr>
              </tfoot>
            </table>

          </div>
        </div>
        <div v-if="page" class="col-12 text-center">
          <button class="btn btn-warning" @click="loadMore">
            <p class="mb-0">
              <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
              {{ $t('load_more') }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <div id="selectorHeader" class="header-export selector-export">
      <div id="headerPage" class="col-12 p-0">
        <ReportHeader
            :company.sync="company_data"
            :supplier.sync="supplier_data"
            :from-date.sync="filters.from_date"
            :title="$t('simple_suppliers_balance_report.account_statement')"
            :to-date.sync="toDate"
        ></ReportHeader>

        <!--        <h4 v-if="company_data" class="text-center">{{ company_data.business_name }}</h4>-->
        <!--        <h4 class="text-center">{{ $t('MENU.simple_suppliers_balance_report') }}</h4>-->
        <!--        <h5 class="text-center">-->
        <!--          <span v-if="date_time_now">{{ $t('time') }} {{ date_time_now }}</span>-->
        <!--        </h5>-->
        <!--        <h5 class="text-center">-->
        <!--          <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }} </span>-->
        <!--          <span v-if="filters.to_date"> {{ $t('to') }} {{ filters.to_date }}</span>-->
        <!--        </h5>-->
        <!--        <div v-if="supplier_data" style="text-align: right;direction: rtl;">-->
        <!--          <p>{{ $t('simple_suppliers_balance_report.supplier') }} : {{ supplier_data.code ? supplier_data.code : '' }} - {{ supplier_data.name ? supplier_data.name : '' }}</p>-->
        <!--          <p>{{ $t('simple_suppliers_balance_report.address') }} : {{ supplier_data.address ? supplier_data.address : '' }}</p>-->
        <!--          <p>{{ $t('simple_suppliers_balance_report.mobile') }} : <span style="text-align: left;direction: ltr;display: inline-block;">{{ supplier_data.phone ? supplier_data.phone : '' }}</span></p>-->
        <!--        </div>-->
      </div>
    </div>
    <div id="selectorExport" class="selector-export"></div>
    <div class="export-container">
      <div id="sectionForExportHeader" class="section-for-export">
<!--        <report-main-header :print-type="'SimpleSuppliersBalanceReport'"></report-main-header>-->
        <ReportHeader
            :company.sync="company_data"
            :detection-for.sync="supplier_data"
            :from-date.sync="filters.from_date"
            :title="$t('simple_suppliers_balance_report.account_statement')"
            :to-date.sync="toDate"
        ></ReportHeader>
      </div>
    </div>
    <!--end::balance-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportHeader from "@/view/content/printing-heads/reports/Main.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import jsPdfExport from "@/core/config/jsPdfExport";
import {mapGetters} from "vuex";

export default {
  name: "index-client-balances",
  components: {ReportMainHeader, ReportHeader},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier/simple-statement',

      date_time_now: null,
      dataList: [],
      dataTotal: [],
      data: [],
      supplier_data: {},
      // company_data: {},
      suppliers: [],
      status_list: [],
      users: [],
      procedures: [],
      balances: {},
      totals: {},

      filters: {
        supplier_id: this.$route.query.supplier_id ? this.$route.query.supplier_id : null,
        from_date: this.$route.query.from_date ? this.$route.query.from_date : null,
        to_date: this.$route.query.to_date ? this.$route.query.to_date : null,
        status: null,
        hide_zero: false,
        show_details: false,
      },
      supplier: {
        id:this.$route.query.supplier_id ? this.$route.query.supplier_id : null,
        business_name: this.$route.query.business_name ? this.$route.query.business_name : null,
      },
      employee: null,
      page: 0,
      current_date: null,

    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
  },
  computed: {
    ...mapGetters({ company_data: "getCompanyData"}),
    json_fields: function () {
      let fields = {};
      fields[this.$t('simple_suppliers_balance_report.date')] = 'reference_date';
      fields[this.$t('simple_suppliers_balance_report.type')] = 'type';
      fields[this.$t('simple_suppliers_balance_report.type_id')] = 'model_code';
      // fields[this.$t('simple_suppliers_balance_report.reference')] = 'code';
      fields[this.$t('simple_suppliers_balance_report.debit_after')] = 'debit_after';
      fields[this.$t('simple_suppliers_balance_report.credit_after')] = 'credit_after';
      fields[this.$t('simple_suppliers_balance_report.amount')] = 'amount';
      fields[this.$t('simple_suppliers_balance_report.currency')] = 'currency_name';

      return fields;
    },

    toDate: function () {
      return (this.filters.to_date ? this.filters.to_date : this.current_date)
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.purchases_reports"),
      route: '/reports/purchase-reports-links'
    }, {title: this.$t("MENU.simple_suppliers_balance_report")}]);

    this.date_time_now = this.$moment().format('DD-MM-YYYY H:mm');
    this.current_date = this.$moment().format('DD-MM-YYYY');

    this.getStatus();

    if (this.$route.query.supplier_id) {
      this.supplier = {
        id: this.$route.query.supplier_id,
        business_name: this.$route.query.business_name,
      }
      this.doFilter();
    }

  },
  methods: {
    ...cssStypeForPrintReport,
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.supplier_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.status = null;
      this.filters.hide_zero = false;
      this.filters.show_details = false;
      this.supplier = null;
      this.employee = null;

      this.getData();
    },

    dataReport() {
      this.setReportLog('excel', 'supplier balance report');
      return this.data;
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    getData() {
      let _filter = {...this.filters};
      // _filter.hide_zero = _filter.hide_zero ? 1 : 0;
      // _filter.show_details = _filter.show_details ? 1 : 0;
      if (_filter.supplier_id) {
        let _limit = (this.page ? this.page : 1) * 15
        ApiService.get(this.mainRoute, {params: {..._filter, limit: _limit}}).then((response) => {
          this.data = response.data.data.data;
          // this.company_data = response.data.data.details.company;
          this.supplier_data = response.data.data.supplier;
          this.balances = response.data.data.balances;
          this.totals = response.data.data.total;
        });
      } else {
        this.$errorAlertMessage(this.$t('please_select_supplier'));
      }

    },

    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get("base/dependency/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`base/dependency/users`, {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
      } else {
        this.users = [];
      }
    },
    getStatus() {
      ApiService.get("base/dependency/natural_account").then((response) => {
        this.status_list = response.data.data;
      });
    },

    getDataList() {
      this.setReportLog('excel', 'supplier balance report');
      let _data = this.data;
      return _data;
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    printData(tableId) {
      this.setReportLog('pdf', 'Simple suppliers Balance Report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let divBalancesTableToPrint = document.getElementById('balancesTable');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divBalancesTableToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
      }, 100)
    },
    printPDFData(tableId) {
      this.setReportLog('pdf', 'Simple Suppliers Balance Report');
      let divBalancesTableToPrint = document.getElementById('balancesTable').cloneNode(true);
      let divToPrint = document.getElementById('headerPage');
      if (divToPrint.querySelector('#balancesTable')){
        divToPrint.querySelector('#balancesTable').remove()
      }
        divToPrint.appendChild(divBalancesTableToPrint)
      exportPDFInnerAction(tableId, 'pdf', this.$t('MENU.simple_suppliers_balance_report'), this.$t('MENU.simple_suppliers_balance_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
  direction: rtl;
  float: right;
  text-align: right;
  display: block;
}

/*.header-export {*/
/*  opacity: 0 !important;*/
/*  z-index: -1000;*/
/*  width: 0.0001px;*/
/*  height: 0.0001px;*/
/*  overflow: auto;*/
/*  direction: rtl;*/
/*  float: right;*/
/*  text-align: right;*/
/*  display: block;*/
/*}*/
</style>
